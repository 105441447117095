import WebComponent from '../webcomponent.js'
import "../components/app-dialog.js"

const template = (obj) => html`
<link rel="stylesheet" href="css/app.css">
<section>
  <header>
    <h1>My product licences for:</h1>
  </header>

  <main>
    ${obj.orgs ? obj.renderOrgs(obj.orgs) : "Loading..."}
  </main>
</section>
`

const orgTemplate = (obj, org) => html`
<details orgId="${org.ruid}">
   <summary>${sanitize(org.fullName)}</summary>
   <p>
   <ul class="content">
      <ion-skeleton-text animated="true"></ion-skeleton-text>
   </ul>
   </p>
 </details>
`

const productTemplate = (obj, product, orgId) => html`
<details productId="${product.id}">
 <summary>${product.name}</summary>
 <p>
 <ul class="content">
   <li><b>Total licences:</b> ${product.quantity}</li>
   <li><b>Unused licences:</b> ${product.quantity - product.quantityInUse}</li>
   <li style="list-style-type: none;"><ion-button
        class="linkAssign" 
        orgId="${orgId}" 
        quantity="${product.quantity - product.quantityInUse}"
        productName="${product.name}"
        productIdentifier="${product.productIdentifier}"
        >Manage licences</ion-button></li>
    </ul>
 </p>
</details>
`

customElements.define("page-licenses", class extends WebComponent {
  async init() {
    let res = await Promise.all([
      app.api.orgList(),
    ])
    this.orgs = res[0].organisations.sort((a,b) => a.fullName.localeCompare(b.fullName))
    this.products = [] // will be populated by current list of products
    this.availableLicenses = 0
    this.render()
  }

  render() {
    this.shadow.innerHTML = template(this)

    let details = this.shadow.querySelectorAll("details[orgId]")
    for (let d of details) {
      d.addEventListener('click', () => {
        this.getSubs(d.attributes['orgId'].value, d)
      })
    }
  }

  renderOrgs(orgs) {
    let ret = ""
    if (!orgs || orgs.length == 0) return "No product licences found.";
    for (let o of orgs) {
      ret += orgTemplate(this, o)
    }

    return ret;
  }

  renderProducts(products, orgId) {
    let ret = ""
    if (!products || !products.length) return "No products found";
    for (let p of products) {
      ret += productTemplate(this, p, orgId)
    }
    return ret;
  }

  async getSubs(orgRuid, details) {
    let container = details.querySelector("ul[class=content]")
    if (container.loaded) return;
    let subs = (await app.api.getSubscriptions(orgRuid)).subscriptions
    let products = {}
    for (let s of subs) {
      for (let r of s.usageRights) {
        if (r.isSellable) {
          if (!products[r.name]) {
            products[r.name] = {
              name: r.name,
              quantity: r.quantity,
              quantityInUse: r.quantityInUse,
              productIdentifier: r.productIdentifier,
              subscriptions: [s]
            }
          } else {
            products[r.name].quantity += r.quantity;
            products[r.name].quantityInUse += r.quantityInUse;
            products[r.name].subscriptions.push(s)
          }
        }
      }
    }
    let ret = []
    for (let p in products) {
      ret.push(products[p])
    }
    this.products = ret;
    let html = this.renderProducts(ret, orgRuid)
    container.innerHTML = html
    container.loaded = true;

    for (let href of container.querySelectorAll(".linkAssign")) {
      href.addEventListener('click', () => {
        location.href = "#manage-licenses?org=" + href.attributes['orgId'].value +
          "&p=" + href.attributes['productIdentifier'].value +
          "&c=" + href.attributes['productName'].value
      })
    }
  }
})
