export default function withOrderUtils(superclass) {
  const orderItemsTemplate = (obj, orgRuid, unitPrice, linePrice, expiryDate) => html`
  <tr>
    <td>
      ${orgRuid && obj.product ?
      (obj.isBundle ? renderBundle(obj, orgRuid)
        : `<a href="#manage-licenses?org=${orgRuid}&p=${obj.identifier}&c=${obj.product}">${obj.description}</a>`)
      : obj.description}
      ${obj.isTrial ? `<ion-chip>${obj.trialDays} day trial</ion-chip>` : ""}
      ${expiryDate ? `<ion-chip>Expiry: ${expiryDate}</ion-chip>` : ""}
    </td>
    <td align="right">${obj.quantity}</td>
    <td align="right">${unitPrice}</td>
    <td align="right">${linePrice}</td>
  </tr>
  `

  const renderBundle = (obj, orgRuid) => html`
    ${obj.description}<br/>
    <ul>
      ${obj.childComponents.map(c => `<li><a href="#manage-licenses?org=${orgRuid}&p=${c.productIdentifier}&c=${c.name}"">${c.name}</a> x${c.quantity}</li>`).join("")}
    </ul>
  `

  const orderCouponsTemplate = (obj, order, coupon) => html`
  <tr>
    <td colspan="3">
      <div style="display: flex; flex-direction: row; align-items: baseline">
        <span class="coupon" ${coupon.applicable ? "valid" : "invalid"}>Coupon ${coupon.code}</span>
        ${coupon.message}
      </div>
    </td>
    <td align="right">${obj.formattedPrice(order, coupon.amount * -1)}</td>
  </tr>
  `

  class OrderUtils extends superclass {

    renderOrderItems(order) {
      let ret = ""
      if (this.statusFilter && !this.statusMatch(order, this.statusFilter.value)) return ret;
      
      for (let i of order.products) {
        let unitPrice = this.getPriceAmount(order, i)
        if (order.response && order.response && order.response.subscription) {
          let right = order.response.subscription.rights.filter(r => r.identifier == i.identifier)[0]
          right.description = right.product
          ret += orderItemsTemplate(right, order.response.purchaser.organisation.ruid, this.formattedPrice(order, unitPrice), this.formattedPrice(order, unitPrice * i.quantity), this.expiryDate(order, right))
        } else {
          ret += orderItemsTemplate(i, order.organisationRuid, this.formattedPrice(order, unitPrice), this.formattedPrice(order, unitPrice * i.quantity), this.expiryDate(order, i))
        }
      }

      if (order.coupons) for (let c of order.coupons) {
        ret += orderCouponsTemplate(this, order, c)
      }

      return ret
    }

    expiryDate(order, product) {
      if (product.isTrial && product.trialDays > 0) {
        let now = new Date(order.updated)
        let expiry = new Date(now.getTime() -
          1000 * 60 * now.getTimezoneOffset() +
          1000 * 60 * 60 * 24 * product.trialDays).toISOString()
        return expiry.replaceAll("T", " ").substring(0, 16)
      }

      return undefined
    }

    getPriceAmount(order, product) {
      if (product.hasOwnProperty("amount")) return product.amount;

      if (order.prices && order.prices.find) {
        let identifier = product.identifier ? product.identifier : product.productIdentifier
        let p = order.prices.find(p => p.hasOwnProperty(identifier))
        if (p) {
          return Number(p[identifier])
        }
      }

      return 0
    }

    formattedPrice(order, amount) {
      if (amount == undefined) return 0
      let currencySymbol = order.currencySymbol
      if (!currencySymbol) {
        currencySymbol = order.currencyIsoCode
      }
      if (!currencySymbol) {
        currencySymbol = ""
      }

      return currencySymbol + " " + amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    }


    organisationName(order) {
      if (order.organisationName) return sanitize(order.organisationName);
      if (order.response && order.response.purchaser && order.response.purchaser.organisation) {
        let o = order.response.purchaser.organisation
        return sanitize(o.name || o.ruid)
      }

      return ""
    }

    statusIcon(order) {
      if (order.isCompleted) return "checkmark-circle";
      if (order.isRunning) return "time";
      if (order.status.toLowerCase() == "awaiting payment") return "pause-circle";
      if (order.status.toLowerCase() == "cancelled") return "stop-circle-outline";
      return "alert-circle";
    }

    statusColor(order) {
      if (order.isCompleted) return "green";
      if (order.isRunning) return "orange";
      if (order.status.toLowerCase() == "awaiting payment") return "blue";
      if (order.status.toLowerCase() == "cancelled") return "silver";
      return "red";
    }

    isAwaitingPayment(order) {
      return order.status == "Awaiting payment";
    }

    isError(order) {
      return !order.isRunning && !order.isCompleted && order.status != "Awaiting payment";
    }

    statusMatch(order, status) {
      if (!order || !order.status) return false;
      switch (status) {
        case "all": return true;
        case "payment": return this.isAwaitingPayment(order);
        case "busy": return order.isRunning;
        case "error": return this.isError(order)
        case "completed": return order.isCompleted;
        case "cancelled": return order.status == "Cancelled";
      }
    }
  }

  return OrderUtils;
}
