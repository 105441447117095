import App from "./app.js"

import "./pages/page-cart.js"
import "./pages/page-payment.js"
import "./pages/page-orders.js"
import "./pages/page-order-created.js"
import "./pages/page-licenses.js"
import "./pages/page-manage-licenses.js"
import "./pages/page-product-list.js"
import "./pages/page-admin-payment.js"
import "./pages/page-contact.js"
import "./pages/page-faq.js"
import "./components/menu-links.js"
import "./components/menu-header.js"
import "./components/menu-footer.js"
import "./components/app-currency.js"
import "./components/app-cart.js"

function routeToPage() {
  let page = "page-product-list"
  if (location.hash.trim().length > 1) {
    let pageName = location.hash.replace("#", "").split("?")[0]
    page = "page-" + pageName
  }

  let content = document.getElementById("content");
  for (let p of content.children) {
    content.removeChild(p);
  }

  if (content.children.length == 0) {
    let pageElement = document.createElement(page)
    pageElement.classList = "page"
    content.appendChild(pageElement)
  }
}

// function to tag string templates as HTML for IDE support
window.html = (strings, ...values) =>
  strings.reduce((acc, str, i) => acc + (i > 0 ? values[i - 1] : '') + str, '');

// function to sanitize strings
window.sanitize = (str) => str.replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&#039;')

window.addEventListener('popstate', routeToPage)

window.addEventListener('load', () => {
  if (!window.localStorage) {
    alert('Please update your browser to the latest version to use this application.');
    return;
  }

  window.app = new App()
  routeToPage()

  window.onerror = function(msg, url, line, col, error) {
    if (window.app && app.progress) app.progress.hide();
    alert(msg)
    return true; // suppress error
  }

  window.onunhandledrejection = (e) => window.onerror(e.reason)
})
