import WebComponent from "../webcomponent.js"

const template = (obj) => html`
<style>@import "/css/app.css"</style>
<select id="currencySelect" style="height:28px; margin-right: 1em;"
  title="Select your prefered currency">
  ${obj.currencies.map(c => `<option value="${c.currencyCode}">${c.display}</option>`)}
</select>
`

customElements.define('app-currency', class extends WebComponent {
  currencies = [
    { display: "🇿🇦 ZAR", currencyCode: "ZAR", currencySymbol: "R" }
  ]

  init() {
    if (localStorage.getItem("currency")) {
      this.currency = localStorage.getItem("currency")
    } else {
      this.currency = "ZAR"
    }
  }

  render() {
    if (!window.app) {
      // app not yet initialized
      setTimeout(() => this.render(), 100)
      return;
    }

    this.shadow.innerHTML = template(this)
    this.currencySelect = this.shadow.querySelector("#currencySelect")
    this.currencySelect.value = this.currency
    this.currencySelect.addEventListener('change', () => {
      this.currency = this.currencySelect.value
      localStorage.setItem("currency", this.currency)
      this.dispatchEvent(new CustomEvent('currency', {
        detail: this.currency
      }))
    })
  }

  currencyCode() {
    return this.currency
  }

  currencySymbol() {
    return this.currencies.filter(c => c.currencyCode == this.currency)[0].currencySymbol
  }
});
