import WebComponent from "../webcomponent.js"

const template = (obj) => html`
    <ion-card>
      <p style="text-align: center; padding: 1em;">
        Please <a href="javascript://" id="lnkLogin">login or register</a> to make your purchase. You may be eligible for discounted pricing for your organisation.
      </p>
    </ion-card>
`

customElements.define('login-banner', class extends WebComponent {
  render() {
    this.shadow.innerHTML = template(this)
    this.shadow.querySelector("#lnkLogin").addEventListener('click', () => app.login())
  }
})
