import WebComponent from "../webcomponent.js"

const template = (obj) => html`
<style>
  @import "css/app.css"
</style>

<ion-header>
  <ion-toolbar style="padding: 24px">
    <ion-icon icon="person-circle-outline" style="width:64px;height:64px"></ion-icon>
<div style="text-wrap: nowrap; text-overflow: ellipsis; overflow: hidden;">${obj.displayName()}</div>
<div style="font-size: smaller">${obj.displayUserName()}</div>
  </ion-toolbar>
  <header>
  </header>
</ion-header>
`

customElements.define('menu-header', class extends WebComponent {
  async render() {
    if (!window.app) {
      // app not yet initialized
      setTimeout(() => this.render(), 100)
      return;
    }

    try {
      this.whoAmI = await app.api.whoAmI()
    } catch (e) {
      // anonymous user
    }
    this.shadow.innerHTML = template(this)

    // broadcast user details
    window.dispatchEvent(new CustomEvent('whoami', {
      detail: this.whoAmI
    }))
  }

  displayName() {
    try {
      return this.whoAmI.person.firstName + " " + this.whoAmI.person.surname
    } catch (e) {
      return "Unknown User"
    }
  }

  displayUserName() {
    try {
      return this.whoAmI.userid.indexOf("@") >= 0 ? this.whoAmI.userid : "@" + this.whoAmI.userid
    } catch (e) {
      return ""
    }
  }

  isSystemAdmin() {
    try {
      return this.whoAmI.isSystemAdmin
    } catch (e) {
      return false
    }
  }
});
