import WebComponent from "../webcomponent.js"

const template = (obj) => html`
<style>
  ion-icon {
  fill: orange;
}
</style>
${obj.reviews > 0 ? `
<ion-icon icon="star${obj.starType(1)}"></ion-icon>
<ion-icon icon="star${obj.starType(2)}"></ion-icon>
<ion-icon icon="star${obj.starType(3)}"></ion-icon>
<ion-icon icon="star${obj.starType(4)}"></ion-icon>
<ion-icon icon="star${obj.starType(5)}"></ion-icon>
${obj.rating} (${obj.reviews})`
    : "Not rated yet"}
`

customElements.define('item-rating', class extends WebComponent {
  init() {
    this.reviews = Number(this.attributes['reviews'].value)
    this.rating = Number(this.attributes['rating'].value)
  }
  render() {
    this.shadow.innerHTML = template(this)
  }

  starType(i) {
    if (this.rating >= i) return "";
    if (i - this.rating < 1) return "-half-outline";
    return "-outline"
  }
});
