import WebComponent from "../webcomponent.js";

// Modal dialog based on https://www.w3schools.com/howto/howto_css_modals.asp
const template = (obj) => html`
<style>
  /* The Modal (background) */
  .modal {
    display: none; /* Hidden by default */
    flex-direction: column;
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }

  /* Modal Content/Box */
  .modal-content {
    display: flex;
    flex-direction: column;
    background-color: #fefefe;
    margin: 15% auto; /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    width: 80%; /* Could be more or less, depending on screen size */
    max-height: 80%;
  }

  /* The Close Button */
  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }

  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  } 

   /* Modal Header */
  .modal-header {
    padding: 2px 16px;
  }

  /* Modal Body */
  .modal-body {
    padding: 2px 16px;
    flex-grow: 1;
    overflow: auto;
  }

  /* Modal Footer */
  .modal-footer {
    padding: 2px 16px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  /* Modal Content */
  .modal-content {
    display: flex;
    flex-direction: column;
    min-height: 250px;
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    border: 1px solid #888;
    border-radius: 8px;
    width: 80%;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    animation-name: animatetop;
    animation-duration: 0.4s
  }

  /* Add Animation */
  @keyframes animatetop {
    from {top: -300px; opacity: 0}
    to {top: 0; opacity: 1}
  } 

  button {
    margin-left: 8px;
    padding: 4px 26px;
  }
</style>

<!-- The Modal -->
<!-- The Modal -->
<div id="myModal" class="modal">

 <!-- Modal content -->
  <div class="modal-content">
    <div class="modal-header">
      <span class="close">&times;</span>
      <h2>${obj.title}</h2>
    </div>
    <div class="modal-body">
      <slot/>
    </div>
    <div class="modal-footer">
      <ion-button fill="clear" id="btnCancel">Cancel</ion-button>
      <ion-button fill="clear" id="btnOk">Ok</ion-button>
    </div>
  </div>
</div>
`

customElements.define('app-dialog', class extends WebComponent {
  render() {
    this.shadow.innerHTML = template(this)
    this.modal = this.shadow.querySelector("#myModal");
    this.span = this.shadow.querySelectorAll(".close")[0];
    this.span.onclick = () => { this.hide() }
    this.modal.onclick = (e) => { if (e.target == this.modal) this.hide() }

    this.btnCancel = this.shadow.querySelector("#btnCancel")
    this.btnCancel.addEventListener('click', () => {
      this.hide()
      this.dispatchEvent(new CustomEvent("cancel"));
      if (this.onCancel) {
        requestAnimationFrame(() => this.onCancel())
      }
    })

    this.btnOk = this.shadow.querySelector("#btnOk")
    this.btnOk.addEventListener('click', () => {
      this.dispatchEvent(new CustomEvent("ok"));
      if (this.onOk) {
        this.shadow.querySelector("#btnOk").disabled = true;
        this.shadow.querySelector("#btnCancel").disabled = true;
        requestAnimationFrame(async () => {
          try {
            await this.onOk()
          } finally {
            this.shadow.querySelector("#btnOk").disabled = false;
            this.shadow.querySelector("#btnCancel").disabled = false;
          }
        })
      }
    })

    this.title = "" // avoid title tooltip
  }

  show() {
    this.modal.style.display = "flex";
  }

  hide() {
    this.modal.style.display = "none";
  }

  open() {
    this.show()
  }

  close() {
    this.hide()
  }

  disableOk() {
    this.btnOk.disabled = true
  }

  enableOk() {
    this.btnOk.disabled = false
  }
})
