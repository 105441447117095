import WebComponent from "../webcomponent.js";

const template = (obj) => html`
<link rel="stylesheet" href="css/app.css">
<style>
  .large-icon {
    width: 32px;
    height: 32px;
    float: left;
    margin: 6px;
  }

  .buttonbar {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: end;
  }
</style>
<h2>Payment admin</h2>
<p><ion-icon name="information-circle-outline" class="large-icon"></ion-icon>
Use this tool to bypass payment for any order, for example if a payment notification failed for a user.
</p>

<ion-input fill="outline" placeholder="Order number" id="orderNumber"></ion-input>
<div class="buttonbar">
  <ion-button id="btnBypassPayment">Bypass payment</ion-button>
</div>
`

customElements.define('page-admin-payment', class extends WebComponent {
  render() {
    this.shadow.innerHTML = template(this)

    this.orderNumber = this.shadow.querySelector("#orderNumber")
    this.shadow.querySelector("#btnBypassPayment").addEventListener('click', () => {
      app.confirm("Are you sure you want to mark this order as paid?", () => {
        app.loadPage('#payment?order_number=' + encodeURIComponent(this.orderNumber.value))
      })
    })
  }
})
