import WebComponent from "../webcomponent.js";

const template = (obj) => html`
<link rel="stylesheet" href="css/app.css">
<section>
  <header>
    <h1>Contact Us</h1>
  </header>
  <p>
			<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14326.502901749152!2d27.9797339!3d-26.1437437!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xde0dd94280f3f303!2sJUMPCO!5e0!3m2!1sen!2sza!4v1675286205310!5m2!1sen!2sza" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
  </p>
  <table style="border:0;width:100%">
    <tr>
      <td valign="top" width="32px"><ion-icon class="medium-icon" name="location"></ion-icon></td>
      <td valign="top" align="left">
          Johannesburg<br/>
          Northcliff Atrium,<br/>
          3rd floor Suite 306,<br/>
          189 Beyers Naude Drive,<br/>
          Northcliff,<br/>
          2195
      </td>
    </tr>
    <tr>
      <td width="32px"><ion-icon class="medium-icon" name="mail"></ion-icon></td>
      <td><a href="mailto:support@jumptrak.co.za">support@jumptrak.co.za</a></td>
    </tr>
    <tr>
      <td width="32px"><ion-icon class="medium-icon" name="logo-whatsapp"></ion-icon></td>
      <td><a href="https://wa.me/27792673397" target="_blank">+27 79 267 3397</a></td>
    </tr>
    <tr>
      <td width="32px"><ion-icon class="medium-icon" name="call"></ion-icon></td>
      <td><a href="tel:+27792673397">+27 79 267 3397</a> (during business hours)</td>
    </tr>
  </table>
</section>
`

customElements.define('page-contact', class extends WebComponent {
  render() {
    this.shadow.innerHTML = template(this)
  }
})
