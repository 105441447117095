import WebComponent from "../webcomponent.js"

const template = (obj) => html`
<ion-button id="btnCart" title="Click to view cart" style="--background: #40b7c4" size="small"><ion-icon slot="icon-only" name="cart-outline"></ion-icon>
  <ion-badge style="--background: #40b7c4">${obj.cartQuantity()}</ion-badge>
</ion-button>
`

customElements.define('app-cart', class extends WebComponent {
  init() {
    if (localStorage.getItem("cart")) {
      try {
        let cart = JSON.parse(localStorage.getItem("cart"))
        this.products = cart.products || []
        this.coupons = cart.coupons || []
      } catch {
        this.products = []
        this.coupons = []
      }
    } else {
      this.products = []
      this.coupons = []
    }
  }

  render() {
    if (!window.app) {
      // app not yet initialized
      setTimeout(() => this.render(), 100)
      return;
    }

    this.shadow.innerHTML = template(this)
    this.shadow.querySelector("#btnCart").addEventListener('click', () => {
      if (this.products.length == 0) {
        alert("Your cart is empty.")
      } else {
        app.loadPage("cart")
      }
    })
  }

  cartQuantity() {
    return this.products.reduce((p, c) => p + Number(c.quantity), 0)
  }

  addProduct(product, quantity, isTrial) {
    if (!product.id) return;
    if (isTrial && !product.allowedTempDays) return;

    let existing = this.products.filter(p => p.id == product.id)
    if (existing.length > 0) {
      if (isTrial || existing[0].isTrial) {
        existing[0].quantity = 1
        existing[0].isTrial = true
      } else {
        existing[0].quantity += Number(quantity)
      }
    } else {
      this.products.push({
        ...product,
        quantity: Number(quantity),
        isTrial
      })
    }
    this.update()
  }

  removeProduct(id) {
    this.products = this.products.filter(p => p.id != id)
    this.update()
  }

  updateQuantity(id, quantity) {
    let product = this.products.filter(p => p.id == id)[0]
    if (product && !product.isTrial) {
      product.quantity = Number(quantity)
    }
    this.update()
  }

  update() {
    localStorage.setItem("cart", JSON.stringify({
      products: this.products,
      coupons: this.coupons
    }))
    this.render()
  }

  getProducts() {
    return this.products;
  }

  getCouponCodes() {
    return this.coupons.map(c => c.couponCode);
  }

  getCoupons() {
    return this.coupons
  }

  hasCoupon(code) {
    return this.coupons.filter(c => c.couponCode == code).length > 0
  }

  addCoupon(couponCode, ability, requirements) {
    if (this.hasCoupon(couponCode)) return; // don't add duplicates
    this.coupons.push({
      couponCode, ability, requirements
    })
    this.update()
  }
  
  removeCoupon(couponCode) {
    let newCoupons = this.coupons.filter(c => c.couponCode !== couponCode)
    this.coupons = newCoupons
  }

  clear() {
    this.products = []
    this.coupons = []
    localStorage.removeItem("cart")
    this.update()
  }
});
