import WebComponent from "../webcomponent.js"

const template = (obj) => html`
<style>
  footer {
    font-size: 12px;
    padding: 2.5em;
    background: #253340;
    color: #8f969d;
  }

  footer a {
    color: white;
  }
</style>

<footer>
  <img src="../../images/jumptrak_bw.png" align="right">
  (c) JumpCO, ${obj.displayYear()}.
  Use of this website constitutes acceptance of our <a href="https://jumptrak.io/terms-of-service/" target="_blank">Terms of Service</a> and <a href="https://jumptrak.io/privacy-policy/" target="_blank">Privacy Policy</a>
</footer>
`

customElements.define('menu-footer', class extends WebComponent {
  render() {
    if (!window.app) {
      // app not yet initialized
      setTimeout(() => this.render(), 100)
      return;
    }

    this.shadow.innerHTML = template(this)
  }

  displayYear() {
    return new Date().getFullYear()
  }
});
